import React from 'react'
import styled from 'styled-components'

const LinksContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  border: 3px solid ${(props) => props.theme.colors.rosyBrown};
  border-radius: 10px;
  padding: 10px 20px;
`

const StyledList = styled.ul`
  margin: 0;

  > li {
    display: inline-block;
    list-style: unset;
    margin-left: unset;
    /* border: 1px solid; */
    /* opacity: 0.6; */
    /* background-color: ${(props) => props.theme.colors.backgroundGrey}; */
    padding: 3px 10px;
    color: ${(props) => props.theme.colors.textWhite};
    /* font-size: 0.8em; */

    &:hover {
      /* opacity: 0.9; */
      /* color: ${(props) => props.theme.colors.softBlue}; */
    }

    + li {
      margin-left: 10px;
    }
  }
`

const LinkList = ({ children }) => {
  console.log(children)

  return (
    <LinksContainer>
      <StyledList>
        {children.map((child, idx) => (
          <li key={idx}>{child}</li>
        ))}
      </StyledList>
    </LinksContainer>
  )
}

export default LinkList