import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Nav = styled.nav`
  flex: 1;
  ul {
    margin-top: 40px;
    text-align: center;
  }
  li {
    margin: 0;
    list-style: none;
    padding: 20px 15px;
    font-size: 1.8em;
  }
  a {
    color: inherit;
    color: #d9e000;
    text-decoration: none;
  }
`

const MainNavigation = () => (
  <Nav>
    <ul>
      <li>
        <Link to="/shopify">Shopify</Link>
      </li>
      <li>
        <Link to="/mechanic">Mechanic</Link>
      </li>
      <li>
        <Link to="/case-studies">Case Studies</Link>
      </li>
      <li>
        <Link to="/contact">Contact</Link>
      </li>
    </ul>
  </Nav>
)

export default MainNavigation
