import React from 'react'
import styled from 'styled-components'

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;

  border: 1px solid white;
  border-bottom: none;
  border-radius: 0.3em 0.3em 0 0;
  background-color: ${({ theme }) => theme.colors.backgroundDarkBlue};
  padding: 0.5em;
  font-size: 0.75em;
`
const CodeBlockHeaderIcon = null

const CodeBlockHeader = ({ title, format, icon, download }) => (
  <StyledHeader>
    <h3>{title}</h3>
    <span>{format}</span>
    {icon && <CodeBlockHeaderIcon />}
  </StyledHeader>
)

export default CodeBlockHeader
