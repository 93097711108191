import React from 'react'
import { Link } from 'gatsby'
// import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons'
import Heading from './Heading'

// TODO: build generic breadcrumbs for tips, tutorials, etc.
const TipsHeading = ({ frontmatter }) => (
  <>
    <Heading frontmatter={frontmatter} />
    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
    <Link to="/mechanic/tips">Mechanic Tips</Link>
  </>
)

export default TipsHeading
