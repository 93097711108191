import React from 'react'
import styled from 'styled-components'
import Tags from './Tags'

const StyledSection = styled.section`
  margin-top: 20px;

  h1 {
    font-size: ${({ theme }) => theme.headings.h1};
    text-align: center;
  }

  hr {
    margin-bottom: 20px;
    width: 200px;
    border-bottom-width: 3px;
    border-color: rosybrown;
  }
`

const Heading = ({ frontmatter }) => (
  <StyledSection>
    <h1>{frontmatter.title}</h1>
    <hr />
    <Tags tags={frontmatter.tags} />
  </StyledSection>
)

export default Heading
