import React, { useState } from 'react'
import { Link } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import styled, { ThemeProvider } from 'styled-components'
import { Toaster } from 'react-hot-toast'
import { GlobalStyle, theme } from '../themes/GlobalStyle'
import Header from './Header'
import Footer from './Footer'
import MainNavigation from './MainNavigation'

// components that can be used in any MDX file without explicit import
import CodeBlockHeader from './CodeBlockHeader'
import ExternalLink from './ExternalLink'
import Heading from './Heading'
import LinkList from './LinkList'
import Tags from './Tags'
import TipsHeading from './TipsHeading'

const components = {
  CodeBlockHeader,
  ExternalLink,
  Heading,
  Link,
  LinkList,
  Tags,
  TipsHeading,
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  width: 90%;
  min-height: 100vh;
`

const Main = styled.main`
  flex: 1;

  a {
    padding-bottom: 2px;
    border-bottom: 2px outset #d9e000;
    color: #d9e000;
    text-decoration: none;

    &:hover {
      color: #f7ff00;
      border-color: #f7ff00;
    }
  }
`

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <MDXProvider components={components}>
        <PageContainer menuOpen={menuOpen}>
          <Toaster />
          <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          {menuOpen ? <MainNavigation /> : <Main>{children}</Main>}
          <Footer />
        </PageContainer>
      </MDXProvider>
    </ThemeProvider>
  )
}

export default Layout
