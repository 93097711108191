import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

export const theme = {
  fonts: {
    sans: `'Work Sans', sans-serif`,
    monospace: `'Fira Code', monospace`,
    cursive: `'Nanum Pen Script', serif`,
  },
  headings: {
    h1: `28px`,
    h2: `24px`,
    h3: `20px`,
  },
  colors: {
    backgroundDarkBlue: `#123`,
    backgroundGrey: `#345`,
    textYellow: `#d9e000`,
    textLight: `#fed`,
    textBlack: `#000`,
    textWhite: `#fff`,
    tagBackground: `#8e432e`,
    softBlue: `#ace`,
    rosyBrown: `rosybrown`,
  },
}

export const GlobalStyle = createGlobalStyle`
  ${reset}

  /* additional resets */
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.fonts.sans};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.textLight};
  }

  body {
    min-height: 100vh;
    line-height: 1.5;
    letter-spacing: 0;
    background-color: ${({ theme }) => theme.colors.backgroundDarkBlue};
  }

  pre {
    font-family: ${({ theme }) => theme.fonts.monospace};
  }

  :root {
    font-size: 1em;

    @media (min-width: 768px) {
      font-size: 1.125em;
    }

    @media (min-width: 1024px) {
      font-size: 1.25em;
    }
  }

  button {
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    background-color: ${({ theme }) => theme.colors.rosyBrown};
    padding: 5px 10px;
    text-shadow: 0px 0px 0px transparent;
    color: ${({ theme }) => theme.colors.textLight};
    cursor: pointer;

    &:active {
      border: none;
    }

    &:focus {
    }
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px auto;
    max-width: 400px;
    width: 90%;
  }

  h2 {
    display: inline-block;
    position: relative;
    margin-top: 2em;
    margin-bottom: 1em;
    font-size: ${({ theme }) => theme.headings.h2};
    text-transform: capitalize;

    &:before {
      position: absolute;
      z-index: -1;
      top: 5px;
      left: -10px;
      width: calc(100% + 20px);
      height: 70%;
      background-color: ${({ theme }) => theme.colors.softBlue};
      opacity: 0.3;
      transform: skewX(-5deg) skewY(-2deg);
      content: "";
    }
  }

  strong {
    font-weight: bold;
  }

  p + p {
    margin-top: 1.5em;
  }

  ul {
    margin: 1em 0;
  }

  li {
    margin-left: 2em;
    list-style: circle;
    + li {
      margin-top: 0.3em;
    }
  }

  /* styling for prismicjs code blocks */
  code[class*="language-"],
  pre[class*="language-"] {
    color: #f8f8f2;
    background: none;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    font-family: ${({ theme }) => theme.fonts.monospace};
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    tab-size: 2;
    hyphens: none;
  }

  pre[class*="language-"] {
    padding: 0;
    margin: .5em 0;
    overflow: auto;
    border-radius: 0.3em;
  }

  pre[class*="language-"].line-numbers {
    position: relative;
    padding-left: 3.8em;
    counter-reset: linenumber;
  }

  pre[class*="language-"].line-numbers > code {
    position: relative;
    white-space: inherit;
  }

  .line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 0;
    font-size: 100%;
    left: -3.8em;
    width: 3em; /* works for line-numbers below 1000 lines */
    letter-spacing: -1px;
    border-right: 1px solid #999;
    user-select: none;
  }

	.line-numbers-rows > span {
		display: block;
		counter-increment: linenumber;
	}

  .line-numbers-rows > span:before {
    content: counter(linenumber);
    color: #999;
    display: block;
    padding-right: 0.8em;
    text-align: right;
  }

  [class^="CodeBlockHeader__StyledHeader"] {
    + .gatsby-highlight {
      margin-top: 0;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
    }
  }
  .gatsby-highlight {
    margin: 2em 0;
    border: 1px solid white;
    border-radius: 0.3em;
    background-color: #111b27;
    padding: 0 0.5em;
    overflow: auto;
  }

  .gatsby-highlight pre[class*="language-"].line-numbers {
    padding: 0;
    padding-left: 2.8em;
    overflow: initial;
  }

  .gatsby-highlight-code-line {
    background-color: #70809040;
    display: block;
  }

  :not(pre) > code[class*="language-"],
  pre[class*="language-"] {
    background: #111b27;
  }

  /* styling for prismicjs inline code */
  :not(pre) > code[class*="language-"] {
    padding: 0.1em 0.3em;
    border-radius: 0.3em;
    white-space: normal;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #8da1b9;
  }

  .token.punctuation {
    color: #e3eaf2;
  }

  .token.delimiter.important,
  .token.selector .parent,
  .token.tag,
  .token.tag .token.punctuation {
    color: #66cccc;
  }

  .token.attr-name,
  .token.boolean,
  .token.boolean.important,
  .token.number,
  .token.constant,
  .token.selector .token.attribute {
    color: #e6d37a;
  }

  .token.class-name,
  .token.key,
  .token.parameter,
  .token.property,
  .token.property-access,
  .token.variable {
    color: #6cb8e6;
  }

  .token.attr-value,
  .token.inserted,
  .token.color,
  .token.selector .token.value,
  .token.string,
  .token.string .token.url-link {
    color: #91d076;
  }

  .token.builtin,
  .token.keyword-array,
  .token.package,
  .token.regex {
    color: #f4adf4;
  }

  .token.function,
  .token.selector .token.class,
  .token.selector .token.id {
    color: #c699e3;
  }

  .token.atrule .token.rule,
  .token.combinator,
  .token.keyword,
  .token.operator,
  .token.pseudo-class,
  .token.pseudo-element,
  .token.selector,
  .token.unit {
    color: #e9ae7e;
  }

  .token.deleted,
  .token.important {
    color: #cd6660;
  }

  .token.keyword-this,
  .token.this {
    color: #6cb8e6;
  }

  .token.important,
  .token.keyword-this,
  .token.this,
  .token.bold {
    font-weight: bold;
  }

  .token.delimiter.important {
    font-weight: inherit;
  }

  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }
`
