import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas, faRobot } from '@fortawesome/free-solid-svg-icons'

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  font-family: ${({ theme }) => theme.fonts.cursive};
  font-size: 1.2 em;

  > svg {
    margin: 0 10px;
  }

  .fa-robot {
    color: ${(props) => props.theme.colors.rosyBrown};
  }

  .fa-globe-americas {
    color: ${(props) => props.theme.colors.softBlue};
  }
`

const Footer = () => (
  <StyledFooter>
    Made with
    <FontAwesomeIcon icon={faRobot} />
    around the
    <FontAwesomeIcon icon={faGlobeAmericas} />
  </StyledFooter>
)

export default Footer
