import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
// import MainMenuToggle from './MainMenuToggle'

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > a {
    margin-top: 20px;
    font-size: 2em;
    color: inherit;
    text-decoration: none;
  }
`
const SubHeader = styled.div`
  font-family: ${({ theme }) => theme.fonts.cursive};
  font-size: 1.5em;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 2em;
  }
`

const Header = ({ menuOpen, setMenuOpen }) => (
  <StyledHeader>
    <Link to="/" title="Home">
      Tekhaus
    </Link>
    <SubHeader>Expertise in Ecommerce Automation</SubHeader>
    {/* <MainMenuToggle menuOpen={menuOpen} setMenuOpen={setMenuOpen} /> */}
  </StyledHeader>
)

export default Header
